import { FasletWidget as FasletWidgetFC } from "@faslet/react-widget";
import { useSession } from "next-auth/react";
import { useCallback, useMemo, useState } from "react";
import { ClientOnly } from "../../ClientOnly";
import { useI18n, usePdp, usePdpAdded } from "../../hooks";
function transSizeToFasletVariants(size, price) {
    return {
        variantId: size.sku,
        inStock: size.isOnStock,
        sku: size.sku,
        colorId: ``,
        price,
    };
}
export const FasletWidget = ({ cartStore, Icon }) => {
    var _a, _b;
    // Get the product variants and colors
    const { $t } = useI18n();
    const [btnLabel, setBtnLabel] = useState("");
    const shopId = process.env.NEXT_PUBLIC_FASLET_SHOP_ID;
    const onAdd = usePdpAdded();
    const { setSku, detail, productData, fasletAvailable } = usePdp();
    const variants = useMemo(() => {
        const price = (productData === null || productData === void 0 ? void 0 : productData.price)
            ? +(productData.price.centAmount / Math.pow(10, productData.price.fractionDigits)).toFixed(2)
            : 0;
        switch (productData === null || productData === void 0 ? void 0 : productData.sizeGroup.type) {
            case "size": {
                return productData === null || productData === void 0 ? void 0 : productData.sizeGroup.sizes.map((i) => ({
                    ...transSizeToFasletVariants(i, price),
                    sizeLabel: i.size,
                }));
            }
            case "waist_length": {
                return productData === null || productData === void 0 ? void 0 : productData.sizeGroup.sizes.map((i) => ({
                    ...transSizeToFasletVariants(i, price),
                    sizeLabel: `${i.waist}/${i.length}`,
                }));
            }
            default: {
                return [];
            }
        }
    }, [productData]);
    const session = useSession();
    const addProductToCart = useCallback(async (id) => {
        const product = variants.find((variant) => variant.variantId === id);
        const sku = (product === null || product === void 0 ? void 0 : product.sku) || "";
        setSku(sku);
        await cartStore.addProductToCart(session.status === "authenticated", sku);
        onAdd();
    }, [cartStore, session, onAdd, setSku, variants]);
    if (!fasletAvailable)
        return <></>;
    if (!shopId)
        return <></>;
    if (!detail.gtmId)
        return <></>;
    if (detail.ctpId !== (productData === null || productData === void 0 ? void 0 : productData.id))
        return <></>;
    return (<ClientOnly>
            <div id="faslet-button" className="faslet-container">
                <FasletWidgetFC productId={detail.gtmId} shopId={shopId} brandId={(productData === null || productData === void 0 ? void 0 : productData.brand) || ""} productImageUrl={((_b = (_a = detail.desktopImages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url) || ""} productName={detail.name} variants={variants} onAddToCart={addProductToCart} onResult={({ label }) => setBtnLabel(label)}/>
                {/* custom button text */}
                <span className="mt-4 inline-block cursor-pointer gap-1 text-sm leading-4" id="faslet-button-text" onClick={() => { var _a, _b; return (_b = (_a = window === null || window === void 0 ? void 0 : window._faslet) === null || _a === void 0 ? void 0 : _a.openWidget) === null || _b === void 0 ? void 0 : _b.call(_a); }}>
                    {btnLabel ? (<>
                            <span>{$t("Your size is")}</span>
                            <span className="ml-2 rounded border border-gray-silver px-2 pt-1.5 pb-1">{btnLabel}</span>
                        </>) : (<span className="flex items-center">
                            {Icon}
                            <span className="ml-1 underline">{$t("Measure indicator")}</span>
                        </span>)}
                </span>
            </div>
        </ClientOnly>);
};
