import { gtmAdpageItemList, navigation, retry } from "@jog/shared";
import { useCallback, useEffect, useState } from "react";
import { usePending } from "../components";
export const useShopTheLook = (keys) => {
    const { setPending } = usePending();
    const [products, setProducts] = useState([]);
    const fetchData = useCallback(async () => {
        const prefix = process.env.NEXT_PUBLIC_TW_ROOT_CATEGORY_ID.slice(0, 4);
        setPending(true);
        await retry(() => navigation(`tn_cid=${prefix}${keys[1]}`).then((res) => res.body.items))
            .then((res) => {
            setProducts(res);
        })
            .finally(() => {
            setPending(false);
        });
    }, [keys, setPending]);
    useEffect(() => {
        fetchData().catch(() => { });
    }, [fetchData]);
    useEffect(() => {
        products && gtmAdpageItemList(products);
    }, [keys, products]);
    return products;
};
