import { PromoInformationSlice, usePdp } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import { PdpPrice } from "./PdpPrice";
import { RatingStar } from "./RatingStar";
const ReviewsSummary = ({ averageRating, count }) => {
    return (<div className="flex">
            <div className="flex items-center whitespace-nowrap">
                <RatingStar rank={averageRating}/>
            </div>
            <span className="mx-2.25">|</span>
            <div className="cursor-pointer hover:text-orange-main" onClick={() => window.scrollTo({
            top: document.getElementById("reviewsContainer").offsetTop - 170,
            behavior: "smooth",
        })}>
                ({Math.round(count)})
            </div>
        </div>);
};
export const Header = ({ reviews }) => {
    var _a;
    const { detail, pdp, productData } = usePdp();
    return (<>
            <div id="name">
                <h1 className="text-22 font-bold uppercase text-black">{detail.name}</h1>
                {((_a = reviews === null || reviews === void 0 ? void 0 : reviews.items) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (<div className="flex flex-col md:flex-row md:items-center">
                        <ReviewsSummary averageRating={reviews.averageRating} count={reviews.total}/>
                    </div>) : null}
                {productData && (<div className="mt-1 flex flex-wrap items-center">
                        <PdpPrice productData={productData}/>
                    </div>)}
            </div>

            {pdp.promoInformation.map((item, index) => (<PromoInformationSlice key={index} color={item.color} richTexts={item.richTexts.filter((i) => isFilled.richText(i))}/>))}
        </>);
};
