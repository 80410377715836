import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { usePdp, usePrevNextButtons } from "../../hooks";
import { Arrow } from "../Icon/Arrow";
import { Link } from "../Link/Link";
import { NextImage } from "../NextImage/NextImage";
const Item = ({ variant, currentId }) => (<div className="shrink-0 grow-0 basis-1/5 pl-1 lg:basis-[18%]" key={variant.itemno}>
        <Link to={variant.url} title={variant.color || variant.title} className="aspect-w-2 aspect-h-3 block" onClick={(event) => currentId === variant.itemno && event.preventDefault()}>
            <NextImage width="667" height="1000" className={classNames("border hover:border-black", currentId === variant.itemno ? "border-black" : "border-gray-silver")} quality={100} src={variant.imageUrl} alt={variant.color || variant.title || ""} title={variant.color}/>
        </Link>
    </div>);
export const GroupSelector = ({ title, Button, }) => {
    const { variants, detail: { ctpId }, } = usePdp();
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "start",
        active: variants.length > 5,
        breakpoints: {
            "(max-width: 991px)": { active: variants.length > 5 },
        },
    });
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    if (variants.length <= 1) {
        return <></>;
    }
    return (<div className="py-2" id="variants">
            {title}
            <div className="relative mt-2">
                <div ref={emblaRef} className="w-full overflow-hidden">
                    <div className="-ml-1 flex touch-pan-y">
                        {variants.map((variant) => (<Item key={variant.itemno} variant={variant} currentId={ctpId}/>))}
                    </div>
                </div>
                <Button className="absolute top-1/2 -left-2 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-arrow disabled:hidden lg:-left-4" type="button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                    <Arrow height={16} width={16}/>
                </Button>
                <Button disabled={nextBtnDisabled} className="absolute top-1/2 -right-2 flex h-8 w-8 translate-x-0.5 -translate-y-1/2 rotate-180 items-center justify-center rounded-full bg-white shadow-arrow disabled:hidden lg:-right-4" type="button" onClick={onNextButtonClick}>
                    <Arrow height={16} width={16}/>
                </Button>
            </div>
        </div>);
};
