import { Favorite } from "@/components/Icon/Favorite";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { cn, useI18n, usePdp, usePdpAdded } from "@jog/react-components";
import { gtmAddToWishlist, gtmRemoveFromWishlist } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useCallback, useMemo } from "react";
const Wishlist = () => {
    var _a;
    const { detail, productData } = usePdp();
    const addWishlistItem = useWishlist.use.addWishlistItem();
    const removeProductFromWishlist = useWishlist.use.removeWishlistItem();
    const favoriteProducts = (_a = useWishlist.use.simpleWishlist()) === null || _a === void 0 ? void 0 : _a.lineItems;
    const lineItemId = useMemo(() => { var _a; return (_a = favoriteProducts === null || favoriteProducts === void 0 ? void 0 : favoriteProducts.find((lineItem) => lineItem.productId === detail.ctpId)) === null || _a === void 0 ? void 0 : _a.id; }, [detail, favoriteProducts]);
    const onFavorite = useCallback(async (e) => {
        var _a;
        e.preventDefault();
        const price = ((_a = ((productData === null || productData === void 0 ? void 0 : productData.firstPrice) || (productData === null || productData === void 0 ? void 0 : productData.price))) === null || _a === void 0 ? void 0 : _a.centAmount) || 0;
        const gtmPrice = +(price / 100).toFixed(2);
        const gtmItem = { id: detail.gtmId, name: detail.name, price: gtmPrice };
        if (!lineItemId) {
            gtmAddToWishlist([gtmItem]);
            await addWishlistItem(detail.ctpId);
        }
        else {
            gtmRemoveFromWishlist([gtmItem]);
            await removeProductFromWishlist(lineItemId);
        }
    }, [addWishlistItem, detail, lineItemId, productData, removeProductFromWishlist]);
    return (<button onClick={onFavorite} className="flex h-12 w-12 items-center justify-center border border-black">
            <Favorite width="32" height="32" className={cn("text-green-dark", lineItemId ? "fill-green-dark" : "fill-white")}/>
        </button>);
};
export const AddToCart = observer(({ className }) => {
    const { cartStore } = useStores();
    const { sku } = usePdp();
    const onAdd = usePdpAdded();
    const session = useSession();
    const addProductToCart = async () => {
        if (!sku) {
            const $size = document.querySelector(`#sizeSelector`);
            if ($size) {
                $size.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            return;
        }
        if (cartStore.isCtaDisabled)
            return;
        await cartStore.addProductToCart(session.status === "authenticated", sku);
        onAdd();
    };
    const { $t } = useI18n();
    return (<div className={cn(className)}>
            <div className="grid grid-cols-[1fr,auto] gap-x-3">
                <button onClick={addProductToCart} className="group flex h-12 w-full items-center justify-center bg-orange-main text-white hover:bg-green-main focus:outline-none md:w-auto">
                    <span className="relative mr-4 w-4">
                        <svg className="absolute top-1/2 left-0 -translate-y-1/2 duration-300 group-hover:left-2" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                            <path d="M1.5 2L7.5 8L1.5 14" stroke="white" stroke-width="3"/>
                        </svg>
                    </span>
                    <span className="font-primary text-base font-bold uppercase">{$t("Add To Cart")}</span>
                </button>
                <Wishlist />
            </div>
        </div>);
});
