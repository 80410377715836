import { FullStar } from "@/components/StarIcon/StarIcon";
const StarSkeletonIcon = (props) => (<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M512 111.616l130.048 263.623 290.93 42.211-210.489 205.255 49.721 289.678L512 775.622 251.79 912.383l49.721-289.678L91.022 417.45l290.93-42.211L512 111.616m0-72.818a72.721 72.721 0 0 0-65.309 40.619L333.596 308.679l-253.042 36.75c-27.421 3.982-50.176 23.211-58.823 49.607a72.957 72.957 0 0 0 18.432 74.638l183.069 178.403-43.235 252.018c-4.665 27.307 6.599 54.955 29.013 71.225 12.629 9.216 27.762 13.881 42.781 13.881 11.605 0 23.211-2.731 33.906-8.419l226.304-118.898 226.304 119.011c10.695 5.575 22.301 8.419 33.906 8.419 15.133 0 30.151-4.665 42.781-13.881 22.414-16.27 33.678-43.918 29.013-71.225L800.77 648.19l183.069-178.403c19.797-19.342 26.965-48.242 18.432-74.638s-31.403-45.625-58.823-49.607l-253.042-36.75L577.311 79.53c-12.288-25.031-37.547-40.733-65.309-40.733z" fill="currentColor"></path>
    </svg>);
const Star = ({ score }) => {
    if (score >= 100) {
        return (<span className="block h-4 w-4">
                <FullStar width={16} height={16} className="text-green-main"/>
            </span>);
    }
    if (score <= 0) {
        return (<span className="block h-4 w-4">
                <StarSkeletonIcon width={16} height={16} className="text-green-main"/>
            </span>);
    }
    return (<span className="relative block h-4 w-4">
            <span className="absolute left-0 right-0 top-0 z-[1]">
                <StarSkeletonIcon width={16} height={16} className="text-green-main"/>
            </span>
            <span className="absolute left-0 top-0 z-[2] overflow-hidden" style={{ width: score + "%" }}>
                <FullStar width={16} height={16} className="text-green-main"/>
            </span>
        </span>);
};
export const RatingStar = ({ rank }) => {
    const int = Math.floor(rank);
    const stars = Array.from({ length: 5 })
        .fill(0)
        .map((_, i) => {
        if (i === int) {
            return Math.round(rank * 100 - int * 100);
        }
        if (i + 1 <= int)
            return 100;
        return 0;
    });
    return (<div className="mr-px flex space-x-px">
            {stars.map((score, i) => (<Star score={score} key={i}/>))}
        </div>);
};
