import { usePromotionTag } from "@/lib/preFetch/prefetchPromoTag";
import { ContentLoader, NextImage, PromotionalImage, useI18n, usePdp, usePdpImages, usePdpImagesMobile, usePrevNextButtons, useSwipeable, } from "@jog/react-components";
import { Arrow } from "@jog/react-components/src/components/Icon/Arrow";
import classNames from "classnames";
import { useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { Cross } from "../Icon/Cross";
const MobileImageZoom = ({ image, index }) => {
    const [close, setClose] = useState(false);
    const id = `image_zoom_${index}`;
    return (<div className="relative w-full shrink-0 grow-0 basis-full">
            <InnerImageZoom hideCloseButton={true} zoomSrc={image.highest} src={image.url} className="w-full" imgAttributes={{ className: "w-full", id }} afterZoomIn={() => setClose(true)} afterZoomOut={() => setClose(false)}/>
            {close ? (<button onClick={() => {
                var _a;
                ;
                (_a = document.querySelector(`#${id}`)) === null || _a === void 0 ? void 0 : _a.click();
            }} className="absolute right-2.5 bottom-2.5 flex h-10 w-10 items-center justify-center bg-white/80 ">
                    <Cross className="h-6 w-6 fill-current text-grey-medium"/>
                    <span className="sr-only">zoom out</span>
                </button>) : (<></>)}
        </div>);
};
export const Carousel = () => {
    var _a, _b, _c, _d, _e;
    const { detail: { brand, desktopImages, mobileImages }, productData, } = usePdp();
    const brandImage = brand ? (<div className="absolute top-4 left-3 z-[2] w-40">
            <NextImage className="h-auto w-full" height={brand.height} width={brand.width} src={brand.url} alt={brand.alt}/>
        </div>) : (<ContentLoader height="25px" width="120px" className="absolute top-2.5 left-5 z-[2]"/>);
    const { $t } = useI18n();
    const desktop = useSwipeable(desktopImages.length);
    const [ref, emblaApi] = usePdpImages(desktopImages.length);
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    const mobileCarousel = usePdpImagesMobile();
    const mobile = usePrevNextButtons(mobileCarousel[1]);
    const promotionalBanner = ((_b = (_a = productData === null || productData === void 0 ? void 0 : productData.promotionalBanner) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b[process.env.NEXT_PUBLIC_LOCALE]) || "";
    const promotionTag = usePromotionTag(promotionalBanner);
    console.log({ promotionalBanner, promotionTag });
    const promotion = promotionTag ? (<PromotionalImage className="absolute right-3 top-4 z-[2] w-24 lg:right-4" promotionTag={promotionTag}/>) : (<></>);
    if (!desktopImages.length) {
        return <div>{$t("There are no images for this product")}</div>;
    }
    return (<>
            <div id="desktopThumbnails" className="relative hidden h-full lg:block lg:p-[var(--carousel-space-out)]">
                <div className="sticky top-[var(--carousel-space-out)]">
                    <div className={classNames("relative grid justify-between gap-x-[var(--carousel-space-x)]", "lg:grid-cols-[var(--lg-slide-w),var(--lg-zoom-w)] lg:grid-rows-[var(--lg-zoom-h)]", "xl:grid-cols-[var(--xl-slide-w),var(--xl-zoom-w)] xl:grid-rows-[var(--xl-zoom-h)]", "2xl:grid-cols-[var(--xxl-slide-w),var(--xxl-zoom-w)] 2xl:grid-rows-[var(--xxl-zoom-h)]")}>
                        <div className="relative">
                            <div ref={ref} className="overflow-hidden">
                                <div className="-mt-[var(--carousel-space-y)] flex touch-pan-x flex-col lg:h-[var(--lg-slide-container-h)] xl:h-[var(--xl-slide-container-h)] 2xl:h-[var(--xxl-slide-container-h)]">
                                    {desktopImages.map((image, i) => (<div key={i} className="min-h-0 shrink-0 grow-0 pt-[var(--carousel-space-y)] ">
                                            <div className="aspect-w-2 aspect-h-3" onClick={() => desktop.setActive(i)}>
                                                <NextImage src={image.lowest} alt="Thumbnail image" width={60} height={90} className={classNames("cursor-pointer border hover:border-black lg:h-[var(--lg-slide-h)] xl:h-[var(--xl-slide-h)] 2xl:h-[var(--xxl-slide-h)]", "h-full w-full object-cover", i === desktop.active ? "border-gray-base" : "border-gray-silver")}/>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div>
                                <button className="absolute -top-3.5 left-1/2 flex h-7 w-7 -translate-x-1/2 rotate-90 items-center justify-center rounded-full bg-white shadow hover:text-green-dark disabled:hidden" type="button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                                    <Arrow height={16} width={16}/>
                                </button>
                                <button disabled={nextBtnDisabled} className="absolute -bottom-3.5 left-1/2 flex h-7 w-7 -translate-x-1/2 -rotate-90 items-center justify-center rounded-full bg-white shadow hover:text-green-dark disabled:hidden" type="button" onClick={onNextButtonClick}>
                                    <Arrow height={16} width={16}/>
                                </button>
                            </div>
                        </div>
                        <div className="relative z-[1] h-auto touch-pan-y">
                            {brandImage}
                            {promotion}
                            <div className="flex">
                                <InnerImageZoom src={(_c = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _c === void 0 ? void 0 : _c.url} imgAttributes={{
            alt: ((_d = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _d === void 0 ? void 0 : _d.label) || "Product Image",
            className: classNames("lg:!h-[var(--lg-zoom-h)] lg:!w-[var(--lg-zoom-w)]", "xl:!h-[var(--xl-zoom-h)] xl:!w-[var(--xl-zoom-w)]", "2xl:!h-[var(--xxl-zoom-h)] 2xl:!w-[var(--xxl-zoom-w)]", "object-contain"),
        }} hideHint zoomSrc={(_e = desktopImages === null || desktopImages === void 0 ? void 0 : desktopImages[desktop.active]) === null || _e === void 0 ? void 0 : _e.highest}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="mobileThumbnails" className="relative block h-full lg:hidden">
                <div className="z-[1] grid h-auto touch-pan-y">
                    {brandImage}
                    {promotion}
                    <div className="overflow-hidden" ref={mobileCarousel[0]}>
                        <div className="flex touch-pan-y touch-pinch-zoom">
                            {mobileImages.map((image, index) => (<MobileImageZoom image={image} index={index} key={index}/>))}
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center justify-center gap-1">
                    {mobileImages.map((_, i) => (<button key={i} className={classNames("h-3 w-3 cursor-pointer rounded-full border", i === mobile.selectedIndex ? "border-black/20 bg-white" : "border-white bg-black/20")} onClick={() => mobile.onDotButtonClick(i)}>
                            <span className="hidden">{`Carousel ${i}+1`}</span>
                        </button>))}
                </div>
            </div>
        </>);
};
