import { Sizes } from "@/components";
import { PdpPrice } from "@/components/Pdp/PdpPrice";
import { useStores } from "@/stores";
import { Link, NextImage, PdpLoader, getProductUrl, useAddedProduct, useI18n } from "@jog/react-components";
import { getGtmIdFromCT, getPdp, gtmAddPdpProductToCart, gtmAdpageAddToCart } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
export const Product = observer(({ product }) => {
    const { cartStore } = useStores();
    const [sku, setSku] = useState("");
    const [pending, setPending] = useState(true);
    const [productData, setProductData] = useState(null);
    const fetchPdpData = useCallback(async () => {
        setPending(true);
        await getPdp(product.itemno)
            .then((res) => {
            setProductData(res);
        })
            .finally(() => {
            setPending(false);
        });
    }, [product.itemno]);
    useEffect(() => {
        fetchPdpData().catch(() => { });
    }, [fetchPdpData]);
    const session = useSession();
    const { $t } = useI18n();
    const { addedProduct } = useAddedProduct();
    const addProductToCart = async () => {
        if (!sku) {
            const $size = document.querySelector(`#size-${productData.id}`);
            if ($size) {
                $size.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            return;
        }
        await cartStore.addProductToCart(session.status === "authenticated", sku);
        addedProduct(sku);
        gtmAddPdpProductToCart(productData, window.location.href);
        gtmAdpageAddToCart(productData, sku, getGtmIdFromCT(productData.attributes), window.location.href);
    };
    const linkItem = getProductUrl(product);
    return (<div className="border-b border-b-gray-silver py-7.5 first:pt-0 last:border-b-0">
            <div className="grid grid-cols-12 grid-rows-[auto_1fr]">
                <div className="col-span-12 lg:col-span-6 lg:col-start-7">
                    <Link href={linkItem} className="pb-0 text-[22px] uppercase text-gray-base lg:pb-2.5 lg:text-[21px]">
                        {product.title}
                    </Link>
                </div>
                <div className="col-span-12 flex items-start lg:col-span-6 lg:row-span-2 lg:row-start-1">
                    <Link href={linkItem} target="_blank" rel="noopener">
                        <NextImage className="img-fluid" src={product.image} alt="Product image" height={240} width={160}/>
                    </Link>
                </div>
                {pending ? (<PdpLoader />) : productData ? (<div id={`size-${productData.id}`} className="col-span-12 lg:col-span-6 lg:col-start-7 lg:row-start-2">
                        <PdpPrice productData={productData}/>
                        <Sizes sizes={productData.sizeGroup} setSku={setSku}/>
                        <div className="mt-6 w-full">
                            <button onClick={addProductToCart} className="group flex h-12 w-full items-center justify-center bg-orange-main text-white hover:bg-green-main focus:outline-none">
                                <span className="relative mr-4 w-4">
                                    <svg className="absolute top-1/2 left-0 -translate-y-1/2 duration-300 group-hover:left-2" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                        <path d="M1.5 2L7.5 8L1.5 14" stroke="white" stroke-width="3"/>
                                    </svg>
                                </span>
                                <span className="font-primary text-base font-bold uppercase">{$t("Add To Cart")}</span>
                            </button>
                        </div>
                    </div>) : (<></>)}
            </div>
        </div>);
});
