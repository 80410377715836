import { Breadcrumbs } from "@/components";
import { Category } from "@/components/Category";
import { Lister } from "@/components/Lister";
import { ShopTheLook } from "@/components/ShopTheLook";
import Layout from "@/layout";
import { PageNotFound, getNotFoundProps } from "@/lib/notFound";
import { getFeaturedProducts } from "@/lib/prismicio/featuredProducts";
import { buildPageProps } from "@/lib/ssg/slug";
import { FeaturedProductsContext, breadcrumbArray, genListerBreadcrumb, useLastLister } from "@jog/react-components";
import { buildCategoryPage as buildCategoryPageBase, prismicClient } from "@jog/shared";
import { useEffect } from "react";
export const buildCategoryPage = async (redisItem, pageContext) => {
    try {
        const client = prismicClient({ previewData: pageContext.previewData });
        const pageProps = await buildCategoryPageBase(client, redisItem, pageContext);
        if (pageProps.type === "category") {
            const featuredProducts = await getFeaturedProducts(pageProps.prismic, pageProps.locale);
            return buildPageProps(pageContext, pageProps, featuredProducts);
        }
        return buildPageProps(pageContext, pageProps);
    }
    catch (e) {
        console.error(e);
        return getNotFoundProps(pageContext);
    }
};
export const ShopTheLookPageContent = ({ page }) => {
    const { setPath } = useLastLister();
    const { categoryInfo, prismic } = page;
    useEffect(() => {
        setPath(`/${page.path}`);
    }, [page.path, setPath]);
    return (<Layout gtmTypeOfPage="lister">
            <Breadcrumbs list={breadcrumbArray(page.path.split("/"))}/>
            <ShopTheLook paths={categoryInfo.paths} keys={categoryInfo.keys} prismic={prismic.data}/>
        </Layout>);
};
export const ListerContent = ({ page }) => {
    const { categoryInfo, prismic } = page;
    const { setPath } = useLastLister();
    useEffect(() => {
        setPath(`/${page.path}`);
    }, [page.path, setPath]);
    return (<Layout gtmTypeOfPage="lister">
            <Breadcrumbs list={genListerBreadcrumb(page.path.split("/"))}/>
            <Lister title={categoryInfo.title} keys={categoryInfo.keys} prismic={prismic === null || prismic === void 0 ? void 0 : prismic.data} path={categoryInfo.path} pretty={categoryInfo.pretty} paths={categoryInfo.paths}/>
        </Layout>);
};
export const CategoryPageContent = ({ page, featuredProducts, }) => {
    const { prismic, categoryInfo } = page;
    const { setPath } = useLastLister();
    useEffect(() => {
        setPath(`/${page.path}`);
    }, [page.path, setPath]);
    if (!prismic)
        return <PageNotFound />;
    return (<Layout gtmTypeOfPage="lister">
            <FeaturedProductsContext.Provider value={{ products: featuredProducts !== null && featuredProducts !== void 0 ? featuredProducts : [] }}>
                <Breadcrumbs list={breadcrumbArray(page.path.split("/"))}/>
                <Category title={categoryInfo.title} prismic={prismic.data} paths={categoryInfo.paths}/>
            </FeaturedProductsContext.Provider>
        </Layout>);
};
