import { CheckMark } from "@/components/Icon/CheckMark";
import { usePdp } from "@jog/react-components";
export const CheckMarks = () => {
    const { pdp: prismicPdp } = usePdp();
    if (!prismicPdp.checkmarkBox.length)
        return <></>;
    return (<ul className="border-t border-black py-6 pl-1 pr-2.5" id="ups">
            {prismicPdp.checkmarkBox
            .map((i) => i.items)
            .flat()
            .map((item, index) => (<li key={index} className="flex items-start px-2 py-0.5">
                        <CheckMark width={24} height={24} className="mr-3 fill-current text-green-main"/>
                        <span className="text-sm font-medium leading-6">{item}</span>
                    </li>))}
        </ul>);
};
