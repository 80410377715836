import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";
import { cn } from "./util";
const AccordionRoot = AccordionPrimitive.Root;
const AccordionItem = AccordionPrimitive.Item;
const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (<AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger ref={ref} className={cn("flex flex-1 items-center justify-between text-left text-sm transition-all [&[data-state=open]>svg]:rotate-180", className)} {...props}>
            {children}
            <svg className="transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 10L12.7071 15.2929C12.3166 15.6834 11.6834 15.6834 11.2929 15.2929L6 10" stroke="#111111" stroke-linecap="round"/>
            </svg>
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (<AccordionPrimitive.Content ref={ref} className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down" {...props}>
        <div className={cn("pt-3", className)}>{children}</div>
    </AccordionPrimitive.Content>));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
export { AccordionRoot, AccordionItem, AccordionTrigger, AccordionContent };
