import { Container, ErrorBoundary, Head } from "@/components";
import { usePdp, usePdpGtm, usePdpStructuredData } from "@jog/react-components";
export const Main = ({ children }) => {
    const { detail: { name, description, paths }, pdp: { metaTagRobots }, } = usePdp();
    const productStructuredDataJSON = usePdpStructuredData();
    usePdpGtm();
    return (<ErrorBoundary system="PDP">
            <Head alternate={paths} title={`${name} | Jeans Centre`} description={description} robots={metaTagRobots}>
                {productStructuredDataJSON && <script type="application/ld+json">{productStructuredDataJSON}</script>}
            </Head>
            <Container>{children}</Container>
        </ErrorBoundary>);
};
