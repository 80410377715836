import { Breadcrumbs } from "@/components";
import { Pdp } from "@/components/Pdp";
import Layout from "@/layout";
import { PageNotFound } from "@/lib/notFound";
import brandsJson from "@/lib/prismicio/prismic-brands.json";
import breadcrumbSpecialCategory from "@/lib/prismicio/prismic-breadcrumb.json";
import pdpJson from "@/lib/prismicio/prismic-pdp.json";
import pdpSizesJson from "@/lib/prismicio/prismic-pdpSizes.json";
import { buildPageProps } from "@/lib/ssg/slug";
import { createClient } from "@jog/ct";
import { PdpProvider, usePdpBreadcrumb } from "@jog/react-components";
import { buildProductDetailInfo, prismicClient, retry, transPdp } from "@jog/shared";
export const buildProductPage = async (redisItem, pageContext) => {
    const { deps, locale, ...others } = redisItem;
    const ctClient = createClient();
    const product = await retry(() => ctClient.product({ id: deps.ct.id, locale1: "en", locale2: locale }).then((res) => res.product));
    if (!product)
        throw new Error(`Not found product from ${redisItem.path}`);
    const prismic = await retry(() => pageContext.previewData
        ? prismicClient({ previewData: pageContext.previewData })
            .getSingle("pdp")
            .then((res) => transPdp(res.data))
        : Promise.resolve(pdpJson));
    return buildPageProps(pageContext, {
        locale,
        ...others,
        productDetailInfo: buildProductDetailInfo({ product, prismic, pdpSizesJson, brandsJson }, locale.toLowerCase()),
        prismic: { ...prismic, sustainable: [] },
    });
};
export const ProductPageContent = ({ page }) => {
    const list = usePdpBreadcrumb(page.path.split("/"), breadcrumbSpecialCategory);
    if (!page.prismic || !page.productDetailInfo || !page.productDetailInfo.published)
        return <PageNotFound />;
    return (<Layout gtmTypeOfPage="pdp">
            <PdpProvider key={page.path} detail={page.productDetailInfo} pdp={page.prismic}>
                <Breadcrumbs list={list}/>
                <Pdp />
            </PdpProvider>
        </Layout>);
};
