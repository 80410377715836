import { transMoney, useI18n, usePdpPrice } from "@jog/react-components";
import classNames from "classnames";
export const PdpPrice = ({ productData }) => {
    const { currentPrice, lowestPrice, originPrice, off } = usePdpPrice(productData);
    const { $t } = useI18n();
    return (<div>
            <div className="flex items-center">
                <div className={classNames("text-[40px] font-bold", off > 0 ? "text-orange-main" : "text-black")}>{`${transMoney(currentPrice)}`}</div>
                {off > 0 ? (<div className="relative ml-12">
                        <div className="absolute right-full top-0 h-0 w-0 border-x-[10px] border-y-[14px] border-r-green-main border-b-transparent border-t-transparent border-l-transparent"></div>
                        <div className="relative flex h-[28px] items-center bg-green-main pl-2 pr-3 text-sm text-white">
                            <span>{`${off}` + $t("Label")}</span>
                        </div>
                    </div>) : (<></>)}
            </div>
            {off > 0 ? (<div className="mt-1 text-sm text-grey-dark">
                    <div>{$t("Original price: {price}", { price: transMoney(originPrice, false) })}</div>
                    {lowestPrice === currentPrice || lowestPrice === originPrice ? null : (<div>{$t(`Lowest price last 30 days: {price}`, { price: transMoney(lowestPrice, false) })}</div>)}
                </div>) : (<></>)}
        </div>);
};
