import { getDomain, gtmAddPdpProductToCart, gtmAdpageAddToCart, gtmAdpageItemDetail, gtmAdpageItemSizeDetail, gtmPdpProductData, localStorage, typedBoolean, } from "@jog/shared";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { kebabCase } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useAddedProduct } from "./useAddedProduct";
import { usePdp } from "./useStaticPdp";
function saveProductExtras(productId, productUrl, pdpData) {
    var _a;
    const cartProductExtras = (_a = localStorage.getItem("cartProductExtras")) !== null && _a !== void 0 ? _a : {};
    cartProductExtras[productId] = { url: productUrl, brand: pdpData.brand || "" };
    localStorage.setItem("cartProductExtras", cartProductExtras);
}
const sortByQuality = ["500", "1000", "2000", "mini"];
export const getItemData = (item, coverType) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const name = item.title;
    const categories = (_c = (_b = (_a = item.attributes) === null || _a === void 0 ? void 0 : _a.find((val) => val.name === "categoryAsUrl" && val.values)) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c[0];
    const slug = (_f = (_e = (_d = item.attributes) === null || _d === void 0 ? void 0 : _d.find((val) => val.name === "slug" && val.values)) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f[0];
    const imageUrl = ((_j = (((_h = (_g = item.attributes) === null || _g === void 0 ? void 0 : _g.find((attribute) => attribute.name === "gallery")) === null || _h === void 0 ? void 0 : _h.values) || [])
        .map((item) => {
        try {
            return JSON.parse(item);
        }
        catch (error) {
            return;
        }
    })
        .filter(typedBoolean)
        .filter((item) => item.label.includes(coverType))
        .map((i) => {
        const quality = sortByQuality.indexOf(i.label.split("-").at(-1) || "");
        return { ...i, quality };
    })
        .filter(({ quality }) => quality > -1)
        .sort((a, b) => a.quality - b.quality)
        .at(0)) === null || _j === void 0 ? void 0 : _j.url) || item.image;
    const color = (_m = (_l = (_k = item.attributes) === null || _k === void 0 ? void 0 : _k.find((val) => val.name === "color" && val.values)) === null || _l === void 0 ? void 0 : _l.values) === null || _m === void 0 ? void 0 : _m[0];
    return {
        imageUrl,
        url: `/${categories}/${kebabCase(name)}/${kebabCase(slug)}`,
        title: item.title,
        itemno: item.itemno,
        color,
    };
};
export const usePdpAdded = () => {
    const { detail, productData, sku } = usePdp();
    const { addedProduct } = useAddedProduct();
    return useCallback(() => {
        if (!productData)
            return;
        saveProductExtras(detail.ctpId, window.location.href, productData);
        gtmAddPdpProductToCart(productData, detail.productCategoryPath);
        addedProduct(sku);
        gtmAdpageAddToCart(productData, sku, detail.gtmId, detail.productCategoryPath);
    }, [addedProduct, detail.ctpId, detail.gtmId, detail.productCategoryPath, productData, sku]);
};
export const usePdpGtm = () => {
    const { productData, sku, detail } = usePdp();
    useEffect(() => {
        sku && productData && gtmAdpageItemSizeDetail(productData, detail.gtmId, detail.productCategoryPath, sku);
    }, [detail, productData, sku]);
    useEffect(() => {
        productData && gtmPdpProductData(productData, detail.gtmId, detail.productCategoryPath);
        productData && gtmAdpageItemDetail(productData, detail.gtmId, detail.productCategoryPath);
    }, [detail, productData]);
};
export const usePdpStructuredData = () => {
    const { detail, productData, sku } = usePdp();
    return useMemo(() => {
        var _a, _b, _c, _d;
        if (!productData)
            return null;
        const { name, description, desktopImages, color, size, material } = detail;
        detail === null || detail === void 0 ? void 0 : detail.material;
        const domain = getDomain();
        const product = {
            "@context": "https://schema.org",
            "@type": "Product",
            "@id": `${domain}/#Product`,
            name,
            image: desktopImages.map((val) => val === null || val === void 0 ? void 0 : val.url),
            offers: {
                "@type": "Offer",
                price: (_b = ((_a = productData.price) === null || _a === void 0 ? void 0 : _a.centAmount) / 100) !== null && _b !== void 0 ? _b : 0,
                priceCurrency: (_d = (_c = productData.price) === null || _c === void 0 ? void 0 : _c.currencyCode) !== null && _d !== void 0 ? _d : "",
                availability: "https://schema.org/InStock",
                itemCondition: "https://schema.org/NewCondition",
            },
            description,
            color,
            brand: productData.brand,
            sku,
            size,
            material,
        };
        return JSON.stringify(product);
    }, [detail, productData, sku]);
};
export const usePdpPrice = ({ firstPrice, price, discountedPrice, lowestPrice }) => {
    const originPrice = firstPrice || price;
    if (discountedPrice && discountedPrice.centAmount < originPrice.centAmount) {
        const currentPrice = discountedPrice.centAmount;
        const _originPrice = originPrice.centAmount;
        return {
            currentPrice: currentPrice,
            originPrice: _originPrice,
            lowestPrice,
            off: Math.floor(((_originPrice - currentPrice) / _originPrice) * 100),
        };
    }
    const currentPrice = price.centAmount;
    const _originPrice = (firstPrice === null || firstPrice === void 0 ? void 0 : firstPrice.centAmount) || price.centAmount;
    return {
        currentPrice,
        originPrice: _originPrice,
        lowestPrice,
        off: Math.floor(((_originPrice - currentPrice) / _originPrice) * 100),
    };
};
export const usePdpImagesMobile = () => useEmblaCarousel({ axis: "x", align: "start", loop: true });
export const usePdpImages = (length) => useEmblaCarousel({
    axis: "y",
    align: "start",
    active: length > 5,
}, [WheelGesturesPlugin()]);
export const genPdpImageStyle = (space, width, imagesLength) => {
    const gen = (slideW) => {
        const slideH = (slideW / 2) * 3;
        const zoomH = slideH * 5 + space.y * 4 + (imagesLength > 5 ? 0.4 * slideH : 0);
        const zoomW = (zoomH / 3) * 2;
        return {
            ["slide-w"]: `${slideW}px`,
            ["slide-h"]: `${slideH}px`,
            ["slide-container-h"]: `${zoomH + space.y}px`,
            ["zoom-w"]: `${zoomW}px`,
            ["zoom-h"]: `${zoomH}px`,
            ["carousel-w"]: `${zoomW + slideW + space.out * 2 + space.x}px`,
        };
    };
    const lg = gen(width.lg);
    const xl = gen(width.xl);
    const xxl = gen(width.xxl);
    return {
        ["--carousel-space-x"]: `${space.x}px`,
        ["--carousel-space-y"]: `${space.y}px`,
        ["--carousel-space-out"]: `${space.out}px`,
        ["--lg-slide-w"]: lg["slide-w"],
        ["--lg-slide-h"]: lg["slide-h"],
        ["--lg-slide-container-h"]: lg["slide-container-h"],
        ["--lg-zoom-w"]: lg["zoom-w"],
        ["--lg-zoom-h"]: lg["zoom-h"],
        ["--lg-carousel-w"]: lg["carousel-w"],
        ["--xl-slide-w"]: xl["slide-w"],
        ["--xl-slide-h"]: xl["slide-h"],
        ["--xl-slide-container-h"]: xl["slide-container-h"],
        ["--xl-zoom-w"]: xl["zoom-w"],
        ["--xl-zoom-h"]: xl["zoom-h"],
        ["--xl-carousel-w"]: xl["carousel-w"],
        ["--xxl-slide-w"]: xxl["slide-w"],
        ["--xxl-slide-h"]: xxl["slide-h"],
        ["--xxl-slide-container-h"]: xxl["slide-container-h"],
        ["--xxl-zoom-w"]: xxl["zoom-w"],
        ["--xxl-zoom-h"]: xxl["zoom-h"],
        ["--xxl-carousel-w"]: xxl["carousel-w"],
    };
};
