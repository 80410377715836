import { Container, ErrorBoundary, Head, LogoLoader } from "@/components";
import { Product } from "@/components/ShopTheLook/Product";
import { useStores } from "@/stores";
import { NextImage, useI18n, useShopTheLook } from "@jog/react-components";
import { asImageSrc, asText } from "@prismicio/helpers";
import { observer } from "mobx-react";
export const ShopTheLook = observer(({ keys, prismic, paths, }) => {
    var _a, _b, _c, _d;
    const { cartStore } = useStores();
    const { $t } = useI18n();
    const products = useShopTheLook(keys);
    return (<ErrorBoundary system="ShopTheLook">
                <Head alternate={paths} title={asText(prismic.meta_tag_title)} description={asText(prismic.meta_tag_description)} robots={asText(prismic.meta_tag_robots)}/>
                <Container className="bg-white">
                    {cartStore.addProductFetching && <LogoLoader />}
                    <div className="grid grid-cols-[auto_1fr] grid-rows-[auto_1fr] gap-x-7 p-5">
                        <div className="col-span-12 row-start-1 lg:col-span-1 lg:col-start-2">
                            <div>
                                <h1 className="mb-5 text-3.5xl font-bold uppercase">
                                    {asText(prismic.title) || $t("Shop the look")}
                                </h1>
                                <h6 className="mb-5 text-17 uppercase">{asText(prismic.subtitle)}</h6>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-1 lg:row-span-2">
                            <NextImage src={asImageSrc(prismic.main_image)} width={(_b = (_a = prismic.main_image) === null || _a === void 0 ? void 0 : _a.dimensions) === null || _b === void 0 ? void 0 : _b.width} height={(_d = (_c = prismic.main_image) === null || _c === void 0 ? void 0 : _c.dimensions) === null || _d === void 0 ? void 0 : _d.height} alt="Product image" className="w-full lg:w-[400px] xl:w-[600px] 2xl:w-[680px]"/>
                        </div>
                        <div className="col-span-12 row-start-3 lg:col-span-1 lg:col-start-2 lg:row-start-2">
                            <div className="mt-3.75 py-3.75 pr-3.75 text-sm [&_a]:text-gray-dracula [&_a]:no-underline [&_a]:hover:cursor-pointer">
                                {asText(prismic.description)}
                            </div>
                            <div className="pt-5">
                                {products.map((product, index) => (<Product key={index} product={product}/>))}
                            </div>
                        </div>
                    </div>
                </Container>
            </ErrorBoundary>);
});
