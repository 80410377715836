import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, useI18n, usePdp, } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
const RichText = ({ field }) => {
    return (<PrismicRichText field={field} components={{
            strong: ({ text }) => <>{text}</>,
            heading1: ({ children }) => <h1 className="font-normal uppercase">{children}</h1>,
            heading2: ({ children }) => <h2 className="font-normal uppercase">{children}</h2>,
            heading3: ({ children }) => <h3 className="font-normal uppercase">{children}</h3>,
            heading4: ({ children }) => <h4 className="font-normal uppercase">{children}</h4>,
            heading5: ({ children }) => <h5 className="font-normal uppercase">{children}</h5>,
            heading6: ({ children }) => <h6 className="font-normal uppercase">{children}</h6>,
            paragraph: ({ children, node, text }) => {
                if (!node.spans.length && !text) {
                    return (<p>
                                <br />
                            </p>);
                }
                return <p>{children}</p>;
            },
            hyperlink: ({ node, children }) => {
                if (node.data.url) {
                    node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                    node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                    node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
                }
                const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
                return (<PrismicLink field={node.data} target={target} className="underline">
                            {children}
                        </PrismicLink>);
            },
        }}/>);
};
export const Sustainable = () => {
    const { detail } = usePdp();
    const { $t } = useI18n();
    const items = detail.sustainable.flat();
    if (items.every((i) => !isFilled.richText(i)))
        return <></>;
    return (<div className="border-t border-black px-4 pt-6 lg:px-2" id="sustainable">
            <AccordionRoot type="single" collapsible defaultValue="">
                <AccordionItem value="sustainable">
                    <AccordionTrigger>{$t("More about the material used")}</AccordionTrigger>
                    <AccordionContent>
                        {detail.sustainable.flat().map((item, index) => (<div key={index} className="text-sm leading-5 text-black">
                                <RichText field={item}/>
                            </div>))}
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </div>);
};
