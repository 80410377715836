import { LogoLoader, Sizes } from "@/components";
import { Ruler } from "@/components/Icon/Ruler";
import { SizeModalButton } from "@/components/Pdp/SizeModal";
import { useProductReviews } from "@/hooks/useProductReview";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { FasletWidget, PdpLoader, genPdpImageStyle, usePdp } from "@jog/react-components";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { AddToCart } from "./AddToCart";
import { Attributes } from "./Attributes";
import { Carousel } from "./Carousel";
import { CheckMarks } from "./CheckMarks";
import { Description } from "./Description";
import { GroupSelector } from "./GroupSelector";
import { Header } from "./Header";
import { Main } from "./Main";
import { Reviews } from "./Reviews";
import { SizeConflict } from "./SizeConflict";
import { Sustainable } from "./Sustainable";
export const Pdp = observer(() => {
    const { cartStore } = useStores();
    const wishlistLoading = useWishlist.use.actionPending();
    const { setSku, pending, productData, detail } = usePdp();
    const style = useMemo(() => genPdpImageStyle({ x: 16, y: 9, out: 16 }, { lg: 84, xl: 106, xxl: 128 }, detail.desktopImages.length), [detail]);
    const { reviews } = useProductReviews();
    return (<Main>
            {(cartStore.addProductFetching || wishlistLoading) && <LogoLoader />}
            <div style={style} className="mb-3.75 grid grid-cols-1 gap-3.75 lg:grid-cols-[var(--lg-carousel-w),1fr] xl:grid-cols-[var(--xl-carousel-w),1fr] 2xl:grid-cols-[var(--xxl-carousel-w),1fr]">
                <div className="col-span-1 bg-white">
                    <Carousel />
                </div>

                <div className="col-span-1 grid h-fit gap-y-7 bg-white px-3 py-8 lg:px-8">
                    <Header reviews={reviews}/>
                    {pending ? (<PdpLoader />) : (<>
                            <GroupSelector />
                            <Sizes sizes={productData.sizeGroup} setSku={setSku}/>
                            {detail.isFasletShow ? (<FasletWidget key={detail.ctpId} cartStore={cartStore} Icon={<Ruler width={20} height={20}/>}/>) : !!detail.sizeImage ? (<SizeModalButton />) : null}
                            <SizeConflict />
                        </>)}
                    <AddToCart className="hidden lg:block"/>
                    {/* CONTAINER - Persuasion Social Proof from sqzly */}
                    <div className="sqzly-proof-contain hidden"/>
                    <div>
                        <CheckMarks />
                        <Attributes />
                        <Description />
                        <Sustainable />
                    </div>
                </div>
            </div>

            <AddToCart className="sticky bottom-0 z-[9] bg-white py-6 px-3 shadow-add-to-cart lg:hidden"/>
            {/* CONTAINER - Recommended product from sqzly */}
            <div id="sqzlyRecommendedProduct" className="sqzly-recommended-product"/>
            {/* CONTAINER - product history from sqzly */}
            <div id="sqzlyProductHistory" className="sqzly-product-history"/>

            <Reviews reviews={reviews}/>
        </Main>);
});
