import { GroupSelector as GroupSelectorBase, useI18n, usePdp } from "@jog/react-components";
import classNames from "classnames";
export const GroupSelector = () => {
    const { $t } = useI18n();
    const { detail } = usePdp();
    return (<GroupSelectorBase title={detail.color && (<div className="text-sm font-medium text-black">
                        {$t("Color: {color}", {
                color: detail.color.toLowerCase(),
            })}
                    </div>)} Button={({ className, ...props }) => (<button {...props} className={classNames(className, "hover:text-green-dark")}/>)}/>);
};
