import { Breadcrumbs } from "@/components";
import { Service } from "@/components/Service";
import Layout from "@/layout";
import { getNotFoundProps } from "@/lib/notFound";
import { fetchService } from "@/lib/prismicio/service";
import { buildPageProps } from "@/lib/ssg/slug";
import { breadcrumbArray } from "@jog/react-components";
import { fetchS3ContentPage, retry } from "@jog/shared";
export const buildServicePage = async (redisItem, pageContext) => {
    const { deps, ...others } = redisItem;
    if (!redisItem.deps) {
        return getNotFoundProps(pageContext);
    }
    const prismic = await retry(() => pageContext.preview && pageContext.previewData
        ? fetchService(redisItem.deps.uid, pageContext.previewData)
        : fetchS3ContentPage("service_page", redisItem.deps.uid));
    return buildPageProps(pageContext, { prismic, ...others });
};
export const ServicePageContent = ({ page }) => {
    return (<Layout>
            <Breadcrumbs list={breadcrumbArray(page.path.split("/"))} displayInMobile/>
            <Service prismic={page.prismic.data}/>
        </Layout>);
};
